import React, { useRef, useMemo } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

interface HandPairProps {
  landmarks: any[][];
  color: string;
  meshRef: THREE.InstancedMesh | undefined;
}

const HandPair: React.FC<HandPairProps> = ({ landmarks, color, meshRef }) => {
  const localMeshRef = useRef<THREE.InstancedMesh>(null);
  const colorRef = useRef(new THREE.Color(ensureValidColor(color)));

  const geometry = useMemo(() => new THREE.SphereGeometry(0.01, 32, 32), []);
  const material = useMemo(() => new THREE.MeshBasicMaterial(), []);

  // console.log(`landmarks: ${JSON.stringify(landmarks)}, meshRef: ${meshRef ? 'defined' : 'undefined'}`);

  useFrame(() => {
    const mesh = meshRef || localMeshRef.current;
    if (!mesh) {
      console.warn('Mesh is undefined. Using meshRef:', meshRef, 'localMeshRef:', localMeshRef.current);
      return;
    }
    if (landmarks.length === 0) {
      console.warn('No landmarks available');
      return;
    }

    const matrix = new THREE.Matrix4();
    const instanceColor = new THREE.Color();

    let index = 0;
    
    for (const handLandmarks of landmarks) {
      const now = Date.now();
      const beatDuration = 250;
      const beatProgress = (now % beatDuration) / beatDuration;
      const sparkleIntensity = Math.pow(Math.sin(beatProgress * Math.PI), 10);

      for (const landmark of handLandmarks) {
        // Update the position calculation
        matrix.setPosition(landmark.x * 2 - 1, -landmark.y * 2 + 1, landmark.z);
        mesh.setMatrixAt(index, matrix);

        instanceColor.copy(colorRef.current).lerp(new THREE.Color(0xffffff), sparkleIntensity);
        mesh.setColorAt(index, instanceColor);

        index++;
      }
    }

    mesh.count = index;
    mesh.instanceMatrix.needsUpdate = true;
    // Add a null check for instanceColor
    if (mesh.instanceColor) {
      mesh.instanceColor.needsUpdate = true;
    }
  });

  if (landmarks.length === 0) return null;

  return (
    <instancedMesh
      ref={localMeshRef}
      args={[geometry, material, 42 * 4]}
      frustumCulled={false}
    />
  );
};

// Add this helper function at the end of the file
function ensureValidColor(color: string): string {
  return color.startsWith('#') ? color : `#${color}`;
}

export default HandPair;
