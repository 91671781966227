"use client";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { HandLandmarker, FilesetResolver } from "@mediapipe/tasks-vision";
import { Canvas } from "@react-three/fiber";
import usePartySocket from "partysocket/react";
import EnableWebcamButton from "./EnableWebcamButton";
import { randomColor } from "../utils";
import HandScene from "./HandScene";

const HandLandmarkerComponent: React.FC = () => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [handLandmarker, setHandLandmarker] = useState<HandLandmarker | null>(null);
  const [webcamRunning, setWebcamRunning] = useState(false);
  const landmarksRef = useRef({});
  const handsColourRef = useRef<{ [key: string]: string }>({});
  const myLandmarksRef = useRef<any[][]>([]);
  const frameCountRef = useRef(0);
  const [videoReady, setVideoReady] = useState(false);

  const socket = usePartySocket({
    room: "condense-game-jam",
    onMessage(evt) {
      const data = JSON.parse(evt.data);
      landmarksRef.current = data;

      for (const key in data) {
        if (!handsColourRef.current[key]) {
          handsColourRef.current[key] = randomColor().getHexString();
        }
      }
    },
  });

  const createHandLandmarker = useCallback(async () => {
    const vision = await FilesetResolver.forVisionTasks(
      "https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@0.10.0/wasm"
    );
    const handLandmarker = await HandLandmarker.createFromOptions(vision, {
      baseOptions: {
        modelAssetPath: `https://storage.googleapis.com/mediapipe-models/hand_landmarker/hand_landmarker/float16/1/hand_landmarker.task`,
        delegate: "GPU"
      },
      runningMode: "VIDEO",
      numHands: 2
    });
    setHandLandmarker(handLandmarker);
  }, []);

  useEffect(() => {
    createHandLandmarker();
  }, [createHandLandmarker]);

  const enableWebcam = useCallback(async () => {
    if (!handLandmarker) {
      console.log("Wait! HandLandmarker not loaded yet.");
      return;
    }

    const constraints = { video: true };
    try {
      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      const video = videoRef.current;
      if (video) {
        video.srcObject = stream;
        video.addEventListener("loadeddata", () => {
          setVideoReady(true);
          predictWebcam();
        });
      }
      setWebcamRunning(true);
    } catch (error) {
      console.error("Error accessing webcam:", error);
    }
  }, [handLandmarker]);

  const predictWebcam = useCallback(() => {
    const video = videoRef.current;
    if (!video || !handLandmarker) {
      return;
    }

    let startTimeMs = performance.now();
    const results = handLandmarker.detectForVideo(video, startTimeMs);

    if (results.landmarks && results.landmarks.length > 0) {
      myLandmarksRef.current = results.landmarks;
      frameCountRef.current += 1;
      socket.send(JSON.stringify({ landmarks: results.landmarks }));
    }

    window.requestAnimationFrame(predictWebcam);
  }, [handLandmarker, socket]);

  return (
    <div style={{ width: "100vw", height: "100vh" }}>
      {!webcamRunning && <EnableWebcamButton onClick={enableWebcam} />}
      <video
        ref={videoRef}
        autoPlay
        playsInline
        style={{
          position: "absolute",
          top: "10px",
          left: "10px",
          width: "160px",
          height: "120px",
          transform: "scaleX(-1)",
          zIndex: 1,
        }}
      />
      {videoReady && (
        <Canvas style={{ width: "100%", height: "100%" }}>
          <HandScene
            landmarksRef={landmarksRef}
            myLandmarksRef={myLandmarksRef}
            handsColourRef={handsColourRef}
            socketId={socket.id}
          />
        </Canvas>
      )}
    </div>
  );
};

export default HandLandmarkerComponent;
