import React, { useState } from "react";

interface EnableWebcamButtonProps {
  onClick: () => void;
}

const EnableWebcamButton: React.FC<EnableWebcamButtonProps> = ({ onClick }) => {
  const [isDissolving, setIsDissolving] = useState(false);

  const handleClick = () => {
    setIsDissolving(true);
    setTimeout(onClick, 1000); // Delay the onClick callback
  };

  if (!isDissolving) {
    return (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 2,
        }}
      >
        <button
          onClick={handleClick}
          style={{
            padding: "15px 30px",
            fontSize: "18px",
            fontWeight: "bold",
            color: "white",
            backgroundColor: "#4CAF50",
            border: "none",
            borderRadius: "8px",
            cursor: "pointer",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1), 0 0 20px rgba(76, 175, 80, 0.5)",
            transition: "all 0.3s",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <div style={{ position: 'relative', overflow: 'hidden' }}>
            {['Dance! Dance! Dance!', 'with your', 'Hands Hands Hands'].map((text, index) => (
              <div
                key={index}
                style={{
                  marginBottom: index < 2 ? '10px' : '0',
                  animation: `
                    dance 0.5s ease-in-out infinite alternate,
                    pulse 0.5s ease-in-out infinite alternate
                  `,
                  animationDelay: `${index * 0.1}s`,
                }}
              >
                {text}
              </div>
            ))}
          </div>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              pointerEvents: "none",
            }}
          >
            {[...Array(30)].map((_, index) => (
              <div
                key={index}
                style={{
                  position: "absolute",
                  backgroundColor: getRandomColor(),
                  width: `${Math.random() * 10 + 2}px`,
                  height: `${Math.random() * 10 + 2}px`,
                  borderRadius: "50%",
                  animation: `crazySparkle ${Math.random() * 2 + 1}s infinite`,
                  animationDelay: `${index * 0.1}s`,
                  left: `${Math.random() * 150 - 25}%`,
                  top: `${Math.random() * 150 - 25}%`,
                  boxShadow: `0 0 ${Math.random() * 5 + 2}px ${getRandomColor()}`,
                }}
              />
            ))}
          </div>
        </button>
      </div>
    );
  }

  const particleCount = 100;
  const colors = ["#4CAF50", "#45a049", "#66bb6a", "#81c784", "#a5d6a7"];

  return (
    <div
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        zIndex: 2,
        width: "200px",
        height: "200px",
      }}
    >
      {[...Array(particleCount)].map((_, i) => {
        const size = Math.random() * 20 + 5;
        const color = colors[Math.floor(Math.random() * colors.length)];
        const angle = Math.random() * Math.PI * 2;
        const radius = Math.random() * 100;
        const duration = Math.random() * 1 + 1;
        const delay = Math.random() * 0.5;

        return (
          <div
            key={i}
            className="particle"
            style={{
              position: "absolute",
              backgroundColor: color,
              width: `${size}px`,
              height: `${size}px`,
              borderRadius: "50%",
              animation: `
                move ${duration}s ease-out forwards,
                fade ${duration}s ease-out forwards,
                scale ${duration}s ease-in-out forwards
              `,
              animationDelay: `${delay}s`,
              left: `${Math.cos(angle) * radius + 100}px`,
              top: `${Math.sin(angle) * radius + 100}px`,
              boxShadow: `0 0 10px ${color}`,
              opacity: 0,
            }}
          />
        );
      })}
    </div>
  );
};

function getRandomColor() {
  const colors = ['#FFD700', '#FF69B4', '#00FFFF', '#FF4500', '#7FFFD4', '#FF1493'];
  return colors[Math.floor(Math.random() * colors.length)];
}

export default EnableWebcamButton;
