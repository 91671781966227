import React, { useRef, useEffect, useState } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";
import HandPair from "./HandPair";

interface HandSceneProps {
  landmarksRef: React.MutableRefObject<any>;
  myLandmarksRef: React.MutableRefObject<any[][]>;
  handsColourRef: React.MutableRefObject<{ [key: string]: string }>;
  socketId: string;
}

const HandScene: React.FC<HandSceneProps> = ({ landmarksRef, myLandmarksRef, handsColourRef, socketId }) => {
  const userMeshesRef = useRef<{ [key: string]: THREE.InstancedMesh }>({});
  const [, forceUpdate] = useState({});

  useFrame(() => {
    // Remove meshes for disconnected users
    for (const key in userMeshesRef.current) {
      if (key !== socketId && !(key in landmarksRef.current)) {
        delete userMeshesRef.current[key];
      }
    }
    // Force update to re-render component
    forceUpdate({});
  });

  useEffect(() => {
    // This effect will run on every render, forcing the component to update
    forceUpdate({});
  }, [landmarksRef.current, myLandmarksRef.current]);

  return (
    <>
      <color attach="background" args={["#000000"]} />
      <orthographicCamera
        left={-1}
        right={1}
        top={1}
        bottom={-1}
        near={0.1}
        far={1000}
        position={[0, 0, 1]}
      />
      {Object.entries(landmarksRef.current).map(
        ([key, landmarksData]) =>
          key !== socketId && (
            <HandPair
              key={key}
              landmarks={landmarksData as any[][]}
              color={handsColourRef.current[key]}
              meshRef={userMeshesRef.current[key]}
            />
          )
      )}
      <HandPair 
        landmarks={myLandmarksRef.current} 
        color="#4CAF50" 
        meshRef={userMeshesRef.current[socketId]} 
      />
    </>
  );
};

export default HandScene;
