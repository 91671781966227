import { HandLandmarkerProvider } from "./components/HandLandmarkerContext";
import Hands from "./components/Hands";
import "./styles.css";
import { createRoot } from "react-dom/client";

function App() {
  return (
    <main>
      <HandLandmarkerProvider>
        <Hands />
      </HandLandmarkerProvider>
    </main>
  );
}

createRoot(document.getElementById("app")!).render(<App />);
