import * as THREE from "three";

export const randomColor = (): THREE.Color => {
  const hue = Math.random(); // Random hue
  const saturation = 1; // Full saturation
  const lightness = 0.5; // Medium lightness for brightness

  const color = new THREE.Color();
  color.setHSL(hue, saturation, lightness);

  return color;
};