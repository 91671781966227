'use client';

import React, { createContext, useContext, useRef, useEffect, useState } from 'react';
import { FilesetResolver, HandLandmarker } from '@mediapipe/tasks-vision';
import type { NormalizedLandmark } from '@mediapipe/tasks-vision';

interface HandLandmarkerContextType {
  landmarks: NormalizedLandmark[][];
  videoRef: React.RefObject<HTMLVideoElement>;
}

export const HandLandmarkerContext = createContext<HandLandmarkerContextType | undefined>(undefined);

export const useHandLandmarker = () => {
  const context = useContext(HandLandmarkerContext);
  if (!context) {
    throw new Error('useHandLandmarker must be used within a HandLandmarkerProvider');
  }
  return context;
};

export const HandLandmarkerProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [landmarks, setLandmarks] = useState<NormalizedLandmark[][]>([]);
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const initHandLandmarker = async () => {
      const vision = await FilesetResolver.forVisionTasks(
        'https://cdn.jsdelivr.net/npm/@mediapipe/tasks-vision@latest/wasm',
      );
      const handLandmarker = await HandLandmarker.createFromOptions(vision, {
        baseOptions: { modelAssetPath: 'hand_landmarker.task' },
        numHands: 2,
      });

      const video = videoRef.current;
      if (video) {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        video.srcObject = stream;
        video.play();

        const detectHandLandmarks = async () => {
          const results = await handLandmarker.detectForVideo(video, performance.now());
          setLandmarks(results.landmarks);
          requestAnimationFrame(detectHandLandmarks);
        };

        detectHandLandmarks();
      }
    };

    initHandLandmarker().catch((error) => {
      console.error('Failed to initialize HandLandmarker:', error);
    });
  }, []);

  return <HandLandmarkerContext.Provider value={{ landmarks, videoRef }}>{children}</HandLandmarkerContext.Provider>;
};
